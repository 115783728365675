export default {
    name: "FixedAssets",
    data() {
      return {
        identity: {
          loanAccountId: "",
          loanData: ""
        },
        property: {          
          listElement: {
            step4: {
              fixedAsset: {
                currentPage: 1,
                rows: 0,
                perPage: 20,
                downloading: false,
                message: "",
              },
            },
          },
          animation: {
            step4: {
              fixedAsset: {
                add: {
                  isLoading: false,
                },
                edit: {
                  isLoading: false,
                },
              },
            },
          },
        },
        table: {
          header: {
            step4: {
              fixedAsset: [],
            },
          },
          data: {
            fixedAsset: [],
          },
        },
      };
    },
    methods: {
      getSessionByAngunanData(){
        setTimeout(() => {
          if (sessionStorage.getItem("SESSION_AGUNAN")) {
            const getAgunanData = sessionStorage.getItem("SESSION_AGUNAN");
            const objData = JSON.parse(getAgunanData);
            if (objData.tloanCollateralFixedAsset != null) {
              this.table.data.fixedAsset = [];
              this.table.data.fixedAsset = objData.tloanCollateralFixedAsset;
            } else {
              this.table.data.fixedAsset = [];
            }
          } else {
            this.table.data.fixedAsset = [];
          }
        }, 2000);
      }
    },
    mounted(){      
      if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
        this.getSessionByAngunanData()      
      }
    },
  };
  