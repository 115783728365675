import Angsuran from "./angsuran";
import Kontrak from "./kontrak";
import Agunan from "./agunan";
import SandiBi from "./sandi-bi";
import ChecklistAdministrasi from "./checklist-administrasi";
import HistoryTransaksiPembiayaan from "./history-transaksi-pembiayaan";

export default {
  name: "InformasiSistemDebitur",
  data() {
    return {
      identifier: {
        savingAccountNumberInstallment: "",
      },
      property: {
        modalPositon: 0,
        modalFasitilasNasabah: false,
      },
      fasilitasList: [],
    };
  },
  components: {
    Angsuran,
    Kontrak,
    Agunan,
    SandiBi,
    ChecklistAdministrasi,
    HistoryTransaksiPembiayaan,
  },
  methods: {
    changeDateFormat(val) {
      Moment.locale("ID");
      if (val !== "") {
        var date = Moment(val).format("LL");
        return date;
      }
    },
    changeFormatRibuan(val) {
      val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    centralBackToList() {
      this.$router.push("/report/sistem-informasi-debitur");
    },
    clickTabs() {
      if (this.property.modalPositon === 6) {
        sessionStorage.setItem(
          "ACCOUNT_NUMBER_FROM_BALANCE_INQUIRY",
          this.identifier.savingAccountNumberInstallment
        );
        this.$router.push("/saving/account-statement");
      }
    },
    async getLoanAccountByLoanAccountNumber() {
      var getDataFromSession = sessionStorage.getItem("LOAN_ACCOUNT_ID");
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl:
            "transaction-loan/findByLoanAccountNumber/" + getDataFromSession,
          endPoint: "loan",
        });
        if (resp.data.code === "SUCCESS") {
          this.identifier.savingAccountNumberInstallment = resp.data.data
            .tsavingAccountInstallment
            ? resp.data.data.tsavingAccountInstallment.accountNumber
            : "";
        }
      } catch (error) {}
    },
    async getFasilitasList() {
      const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_ID");
      const loanAccountId = getTokenFromSession;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/fasilitas",
            payload: {
              loanAccountNumber: loanAccountId,
            },
          });
          if (resp.data.code === "SUCCESS") {
            console.log(resp.data.data);
            this.fasilitasList = resp.data.data;
          } else {
            this.fasilitasList = [];
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            // onConfirm: () => this.routeToPageListLoanAccount(),
          });
        }
      }
    },
    showFasilitasNasabah() {
      this.property.modalFasitilasNasabah = true;
    },
  },
  mounted() {
    this.getLoanAccountByLoanAccountNumber();
    this.getFasilitasList();
  },
};
