import Moment from "moment";

export default {
  name: "ChecklistAdministrasi",
  data() {
    return {
      identity: {
        loanAccountId: "",
      },
      dataForm: {
        data: "",
      },
    };
  },
  methods: {    
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_ID");
      this.identity.loanAccountId = getTokenFromSession;
    },
    async getChecklistDataByLoanId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      } else {
        if (this.identity.loanAccountId != null) {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_LIST_DATA",
              endPoint: "report",
              reqUrl: "report/checklist-administrasi",
              payload: {
                loanAccountNumber : this.identity.loanAccountId,
                name: ""
              }
            });
            if (resp.data.code === "SUCCESS") {
              this.dataForm.data = resp.data.data[0];
            } else {
              this.dataForm.data = '';
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }
      }
    },
  },
  beforeMount() {
    this.getIdentityFromSession();
  },
  mounted() {
    if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
      this.getChecklistDataByLoanId()      
    }
  }
};
