var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5"},[_vm._m(0),_c('div',{staticClass:"flex flex-col items-start w-full"},[_c('table',{staticClass:"table table-custom-report",staticStyle:{"font-size":"12px"}},[_c('tr',[_c('td',[_vm._v("Nomor Kontrak")]),_c('td',{staticStyle:{"width":"1px"}},[_vm._v(":")]),_c('td',[_vm._v(" "+_vm._s(_vm.dataForm.historyTransaksiPembiayaan.noKontrak ? _vm.dataForm.historyTransaksiPembiayaan.noKontrak : "-")+" ")])]),_c('tr',[_c('td',[_vm._v("Nama Nasabah")]),_c('td',{staticStyle:{"width":"1px"}},[_vm._v(":")]),_c('td',[_vm._v(" "+_vm._s(_vm.dataForm.historyTransaksiPembiayaan.nama ? _vm.dataForm.historyTransaksiPembiayaan.nama : "-")+" ")])])])]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.historyTransaksiPembiayaan,"striped":"","bordered":"","narrowed":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center p-3"},[(_vm.property.listElement.historyTransaksiPembiayaan.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(
            _vm.property.listElement.historyTransaksiPembiayaan.downloading ===
              false
          )?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.historyTransaksiPembiayaan.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"no","label":"No"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.index + 1))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"Tanggal","label":"Tanggal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tanggal ? props.row.tanggal : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"keterangan","label":"Keterangan"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.keterangan ? props.row.keterangan : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"nominal","label":"Nominal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.nominal ? _vm.convertDecimalCurrency(props.row.nominal) : props.row.nominal))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"outstandingPokok","label":"Outstanding Pokok"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.outstandingPokok ? _vm.convertDecimalCurrency(props.row.outstandingPokok) : props.row.outstandingPokok))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"outstandingMargin","label":"Outstanding Margin"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.outstandingMargin ? _vm.convertDecimalCurrency(props.row.outstandingMargin) : props.row.outstandingMargin))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"mutasi","label":"Mutasi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.mutasi ? props.row.mutasi : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"batch","label":"Batch"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.batch ? props.row.batch : "-"))])]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center mb-5"},[_c('span',{staticClass:"text-sm font-semibold"},[_vm._v("HISTORY TRANSAKSI LOAN")])])}]

export { render, staticRenderFns }