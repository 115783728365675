import Moment from "moment";
import SistemInformasiDebiturKontrakPdf from "@/components/page/sistem-informasi-debitur-kontrak-pdf";

export default {
  name: "Kontrak",
  components: {
    SistemInformasiDebiturKontrakPdf,
  },
  data() {
    return {
      identity: {
        loanAccountId: "",
      },
      property: {
        listElement: {
          kontrak: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
      },
      dataForm: {
        step1: {
          data: "",
          cifNumber: "",
          cifCategoryName: "",
          cifIdName: "",
        },
      },
      table: {
        data: {
          kontrak: [],
        },
      },
    };
  },
  methods: {
    exportPdf() {
      this.$refs.sistemInformasiDebiturKontrakPdfRef.onOpenPreview({
        dataKontrak: this.dataForm.step1.data,
        // letterhead: {
        //   printDate: `${Moment().format("DD-MM-YYYY")} ${Moment().format(
        //     "HH:mm:ss"
        //   )}`,
        // },
        // printBy: ` ${Moment().format("DD-MM-YYYY")} ${Moment().format(
        //   "HH:mm:ss"
        // )}`,
      });
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_ID");
      this.identity.loanAccountId = getTokenFromSession;
    },
    async getLoanAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      } else {
        if (this.identity.loanAccountId != null) {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_LIST_DATA",
              endPoint: "report",
              reqUrl: "report/contract",
              payload: {
                loanAccountNumber: this.identity.loanAccountId,
              },
            });
            console.log("data kontrak =>", resp);
            if (resp.data.code === "SUCCESS") {
              this.dataForm.step1.data = resp.data.data;
            } else {
              this.dataForm.step1.data = "";
            }
          } catch (error) {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
              // onConfirm: () => this.routeToPageListLoanAccount(),
            });
          }
        }
      }
    },
    changeDateFormat(val) {
      Moment.locale("ID");
      if (val !== "") {
        var date = Moment(val).format("LL");
        return date;
      }
    },
  },
  beforeMount() {
    this.getIdentityFromSession();
  },
  mounted() {
    if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
      this.getLoanAccountById();
    }
  },
};
