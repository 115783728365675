<template>
  <div>
    <b-modal
      :on-cancel="closePreview"
      v-model="showModal"
      :width="1000"
      scroll="clip"
    >
      <div class="modal-card" style="width: auto;">
        <div id="output-canvas" class="modal-card-body w-full p-3">
          <table class="table table-custom-report" style="font-size:11px">
            <!-- NOMOR CIF -->
            <tr>
              <td>Nomor CIF</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.cifNumber ? printOutData.cifNumber : "-" }}
              </td>
            </tr>
            <!-- NAMA CIF -->
            <tr>
              <td>Nama</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.cifIdName ? printOutData.cifIdName : "-" }}
              </td>
            </tr>
            <!-- NOMOR KONTRAK -->
            <tr>
              <td>Nomor Kontrak</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.loanAccountNumber
                    ? printOutData.loanAccountNumber
                    : "-"
                }}
              </td>
            </tr>
            <!-- PRODUK PEMBIAYAAN -->
            <tr>
              <td>Produk Pembiayaan</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.loanName
                    ? printOutData.loanName
                    : "-"
                }}
              </td>
            </tr>
            <!-- STATUS PEMBIAYAAN -->
            <tr>
              <td>Status Pembiayaan</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.loanStatusName
                    ? printOutData.loanStatusName
                    : "-"
                }}
              </td>
            </tr>
            <!-- ALAMAT -->
            <tr>
              <td>Alamat</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.cifIdAddress ? printOutData.cifIdAddress : "-"
                }}
              </td>
            </tr>
            <!-- NO TELPON -->
            <tr>
              <td>No Telpon</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.cifPhoneNumber
                    ? printOutData.cifPhoneNumber
                    : "-"
                }}
              </td>
            </tr>
            <!-- NO HP -->
            <tr>
              <td>No Handphone</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.cifMobilePhoneNumber
                    ? printOutData.cifMobilePhoneNumber
                    : "-"
                }}
              </td>
            </tr>
            <!-- GRUP DEBITUR -->
            <tr>
              <td>Group Debitur</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.groupDebitor ? printOutData.groupDebitor : "-"
                }}
              </td>
            </tr>
            <!-- NO AKAD -->
            <tr>
              <td>Nomor Akad</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.akadNumber ? printOutData.akadNumber : "-" }}
              </td>
            </tr>
            <!-- TANGGAL AKAD -->
            <tr>
              <td>Tanggal Akad</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.akadDate
                    ? dateToMonthText(printOutData.akadDate)
                    : "-"
                }}
              </td>
            </tr>
            <!-- CARA HITUNG MARGIN/BAGI HASIL -->
            <tr>
              <td>Cara Perhitungan Margin / Bagi Hasil</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.loanMarginCalculateName
                    ? printOutData.loanMarginCalculateName
                    : "-"
                }}
              </td>
            </tr>
            <!-- METODE ANGSURAN -->
            <tr>
              <td>Metode Angsuran</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.loanInstallmentPayName
                    ? printOutData.loanInstallmentPayName
                    : "-"
                }}
              </td>
            </tr>
            <!-- JATUH TEMPO -->
            <tr>
              <td>Jatuh Tempo</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.dueDate
                    ? dateToMonthText(printOutData.dueDate)
                    : "-"
                }}
              </td>
            </tr>
            <!-- JANGKA WAKTU -->
            <tr>
              <td>Jangka Waktu</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.timePeriod ? printOutData.timePeriod : "-" }}
              </td>
            </tr>
            <!-- LOKASI -->
            <tr>
              <td>Lokasi</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.officeName ? printOutData.officeName : "-" }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style="width:1px"></td>
              <td class="font-bold">
                Pokok
              </td>
              <td class="font-bold">
                Margin
              </td>
            </tr>
            <tr>
              <td>Plafon</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.principal
                    ? convertDecimalCurrency(printOutData.principal)
                    : "-"
                }}
              </td>
              <td>
                {{
                  printOutData.margin
                    ? convertDecimalCurrency(printOutData.margin)
                    : "-"
                }}
              </td>
            </tr>
            <!-- RATE FLAT -->
            <tr>
              <td>Rate Flat</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.rateFlat ? printOutData.rateFlat : "-" }}
              </td>
            </tr>
            <!-- RATE EFEKTIF -->
            <tr>
              <td>Rate Efektif</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.rateEffective ? printOutData.rateEffective : "-"
                }}
              </td>
            </tr>
            <!-- OUTSTANDING POKOK -->
            <tr>
              <td>Outstanding Pokok</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.outstandingPrincipalNominal
                    ? convertDecimalCurrency(
                        printOutData.outstandingPrincipalNominal
                      )
                    : printOutData.outstandingPrincipalNominal
                }}
              </td>
            </tr>
            <!-- OUTSTANDING MARGIN -->
            <tr>
              <td>Outstanding Margin</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.outstandingMarginNominal
                    ? convertDecimalCurrency(
                        printOutData.outstandingMarginNominal
                      )
                    : printOutData.outstandingMarginNominal
                }}
              </td>
            </tr>
            <!-- NISBAH -->
            <tr>
              <td>Nisbah</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.nisbah ? printOutData.nisbah : "-" }}
              </td>
            </tr>
            <!-- KOLEKTABILITAS HARI INI -->
            <tr>
              <td>Kolektibilitas ( Hari Ini )</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.collectabilityStatusName
                    ? printOutData.collectabilityStatusName
                    : "-"
                }}
              </td>
            </tr>
            <!-- NO REK DROPPING -->
            <tr>
              <td>Nomor Rekening Dropping</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.droppingAccount
                    ? printOutData.droppingAccount
                    : "-"
                }}
              </td>
            </tr>
            <!-- NO REK ANGSURAN -->
            <tr>
              <td>Nomor Rekening Agsuran</td>
              <td style="width:1px">:</td>
              <td>
                {{
                  printOutData.installmentAccount
                    ? printOutData.installmentAccount
                    : "-"
                }}
              </td>
            </tr>
            <!-- AO PROMO -->
            <tr>
              <td>AO Promo</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.aoPromo ? printOutData.aoPromo : "-" }}
              </td>
            </tr>
            <!-- AO HANDLE -->
            <tr>
              <td>AO Handle</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.aoHandle ? printOutData.aoHandle : "-" }}
              </td>
            </tr>
            <!-- COLLECTOR/SURVEYOR -->
            <tr>
              <td>Collector/Surveyor</td>
              <td style="width:1px">:</td>
              <td>
                {{ printOutData.collector ? printOutData.collector : "-" }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <footer class="modal-card-foot">
        <div class="flex flex-row justify-between w-full">
          <b-button @click="closePreview" label="Batal" />
          <b-button
            :loading="isLoading"
            @click="onPressPrint"
            label="Print "
            type="is-link"
          />
        </div>
      </footer>
    </b-modal>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "SistemInformasiDebiturKontrakPdf",
  data() {
    return {
      showModal: false,
      isLoading: false,
      printOutData: "",
    };
  },
  methods: {
    async closePreview() {
      this.showModal = false;
      await this.simpleWait(1000);
      this.printOutData = "";
    },
    onOpenPreview(payload) {
      this.showModal = true;
      this.printOutData = payload.dataKontrak;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        margin: 0.1,
        filename: `Sistem_Informasi_Debitur_Kontrak.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      // New Promise-based usage:
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          pdf.autoPrint();
          const hiddFrame = document.createElement("iframe");
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
        });
    },
  },
};
</script>
