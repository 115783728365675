var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"my-4",attrs:{"data":_vm.table.data.biSandi,"mobile-cards":false,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.sandibi.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.sandibi.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" DATA NOT FOUND ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"sandiBiCode","label":"Sandi BI Code"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.sandiBiCode ? props.row.sandiBiCode : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"sandiBiLevel1Code","label":"BI Code Level 1"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.sandiBiLevel1Code ? props.row.sandiBiLevel1Code : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"sandiBiLevel1Name","label":"BI Nama Level 1"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.sandiBiLevel1Name ? props.row.sandiBiLevel1Name : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"sandiBiLevel2Code","label":"BI Code Level 2"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.sandiBiLevel2Code ? props.row.sandiBiLevel2Code : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"sandiBiLevel2Name","label":"BI Nama Level 2"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.sandiBiLevel2Name ? props.row.sandiBiLevel2Name : "-")+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }