export default {
    name: "SandiBi",
    data() {
      return { 
        identity: {
          loanAccountId: "",
          loanData: ""
        },
        property: {
          listElement: {
            sandibi: {
              downloading: false
            }
          }
        },
        dataForm: {
          data: ""
        },
        table: {
          data: {
            biSandi: []
          }
        }
      };
    },
    methods: {
      getIdentityFromSession() {
        const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_ID");
        this.identity.loanAccountId = getTokenFromSession;
      },
      async getLoanBiCodeById() {
        this.table.data.biSandi = [];
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        } else {
          if (this.identity.loanAccountId != null) {
            try {
              const resp = await this.$store.dispatch({
                type: "GET_LIST_DATA",
                endPoint: "report",
                reqUrl: "report/sandi-bi",
                payload: {
                  loanAccountNumber : this.identity.loanAccountId,
                  name: ""
                }
              });
              if (resp.data.code === "SUCCESS") {
                this.table.data.biSandi = resp.data.data;
                console.log(this.table.data.biSandi)
              } else {
                this.table.data.biSandi = '';
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }
        }
      },
    },
    beforeMount() {
      this.getIdentityFromSession();
    },
    mounted() { 
      if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
        this.getLoanBiCodeById()      
      }
    }
  };
  