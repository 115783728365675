export default {
    name: "Kendaraan",
    data() {
      return {  
        identity: {
          loanAccountId: "",
          loanData: ""
        },      
        property: {
          listElement: {
            step4: {
              vehicle: {
                currentPage: 1,
                rows: 0,
                perPage: 20,
                downloading: false,
                message: "",
              },
            },
          },
          animation: {
            step4: {
              vehicle: {
                add: {
                  isLoading: false,
                },
                edit: {
                  isLoading: false,
                },
              },
            },
          },
        },
        table: {
          header: {
            step4: {
              vehicle: [],
            },
          },
          data: {
            vehicle: [],
          },
        },
      };
    },
    methods: {
      getSessionByAngunanData(){
        setTimeout(() => {
          if (sessionStorage.getItem("SESSION_AGUNAN")) {
            const getAgunanData = sessionStorage.getItem("SESSION_AGUNAN");
            const objData = JSON.parse(getAgunanData);
            if (objData.tloanCollateralVehicle != null) {
              this.table.data.vehicle = objData.tloanCollateralVehicle;
            } else {
              this.table.data.vehicle = [];
            }
          } else {
            this.table.data.vehicle = [];
          }
        }, 2000);
      }
    },
    mounted() { 
      if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
        this.getSessionByAngunanData()      
      }
    },
  };
  