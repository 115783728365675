var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"my-4",attrs:{"data":_vm.table.data.securities,"striped":"","bordered":"","narrowed":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center p-3"},[(_vm.property.listElement.step4.securities.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.step4.securities.downloading === false)?_c('span',[_vm._v(" "+_vm._s(_vm.property.listElement.step4.securities.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"tloanAccount.loanAccountNumber","label":"No Kontrak"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.loanAccountCollateralId ? props.row.loanAccountCollateralId : ""))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"keljaminan","label":"Kel Jaminan"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"},[_vm._v(" - ")])]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"rloanCollateralCategory.collateralCategoryName","label":"Jenis"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"},[_vm._v(" - ")])]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"depositNumber","label":"No Deposito"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v("- "+_vm._s(props.row.depositNumber ? props.row.depositNumber : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"ownerName","label":"Nama"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.ownerName ? props.row.ownerName : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"startDate","label":"Tanggal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.startDate ? props.row.startDate : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"endDate","label":"Jatuh Tempo"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.endDate ? props.row.endDate : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"collateralValue","label":"Nilai"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.collateralValue ? _vm.convertDecimalCurrency(props.row.collateralValue) : props.row.collateralValue))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"bankPublisher","label":"Penerbit"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.bankPublisher ? props.row.bankPublisher : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"rloanCollateralBindType.collateralBindTypeName","label":"Pengikatan"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"},[_vm._v(" - ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }