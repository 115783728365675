export default {
    name: "SuratKeputusan",
    data() {
      return {  
        identity: {
          loanAccountId: "",
          loanData: ""
        },
        property: {
          listElement: {
            step4: {
              decree: {
                currentPage: 1,
                rows: 0,
                perPage: 20,
                downloading: false,
                message: "",
              },
            },
          },
          animation: {
            step4: {
              decree: {
                add: {
                  isLoading: false,
                },
                edit: {
                  isLoading: false,
                },
              },
            },
          },
        },
        table: {
          header: {
            step4: {
              decree: [],
            },
          },
          data: {
            decree: [],
          },
        },
      };
    },
    methods: {      
      getSessionByAngunanData(){
        setTimeout(() => {
          if (sessionStorage.getItem("SESSION_AGUNAN")) {
            const getAgunanData = sessionStorage.getItem("SESSION_AGUNAN");
            const objData = JSON.parse(getAgunanData);
            if (objData.tloanCollateralDecree != null) {
              this.table.data.decree = [];
              this.table.data.decree = objData.tloanCollateralDecree;
            } else {
              this.table.data.decree = [];
            }
          } else {
            this.table.data.decree = [];
          }
        }, 2000);
      }
    },
    mounted(){      
      if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
        this.getSessionByAngunanData()
      }
    }
  };
  