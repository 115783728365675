import Moment from "moment";

export default {
  name: "Angsuran",
  data() {
    return {
      identity: {
        loanAccountNumber: "",
        loanData: "",
      },
      property: {
        listElement: {
          angsuran: {
            currentPage: 1,
            rows: 0,
            perPage: 5,
            downloading: false,
            message: "",
          },
        },
        animation: {
          isDownloadingFile: false,
        },
      },
      dataForm: {
        angsuran: "",
        loanAccountNumber: "",
        name: "",
      },
      table: {
        data: {
          angsuran: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        loanAccountNumber: this.identity.loanAccountNumber,
        name: this.dataForm.name,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/angsuran/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Sistem_Informasi_Debit_Angsuran.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async get_loan_installment_by_loan_id() {
      const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_ID");
      this.identity.loanAccountNumber = getTokenFromSession;
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.listElement.angsuran.downloading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/installments",
            payload: {
              loanAccountNumber: this.identity.loanAccountNumber,
            },
          });
          console.log("data => ", resp);
          if (resp.data.code === "SUCCESS") {
            this.dataForm.angsuran = resp.data.data.header;
            this.table.data.angsuran = resp.data.data.detail;
          } else if (resp.data.code === "DATA_NOT_FOUND") {
            this.property.listElement.angsuran.message =
              "Belum Ada Data Yang Di Proses";
          } else {
            this.property.listElement.angsuran.message = resp.data.message;
          }
        } catch (error) {
          setTimeout(() => {
            this.property.listElement.angsuran.message = error.response
              ? `Gagal Mengunduh Data : ${error.response.statusText}`
              : "Terjadi Kesalahan";
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.angsuran.downloading = false;
          }, timeout);
        }
      }
    },
    changeDateFormat(val) {
      Moment.locale("ID");
      if (val) {
        var date = Moment(val).format("LL");
        return date;
      } else {
        return "-";
      }
    },
    changeFormatRibuan(val) {
      var harga = val
          .toString()
          .split("")
          .reverse()
          .join(""),
        hargaRibuan = harga.match(/\d{1,3}/g);
      hargaRibuan = hargaRibuan
        .join(".")
        .split("")
        .reverse()
        .join("");
      return hargaRibuan;
    },
  },
  mounted() {
    if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
      this.get_loan_installment_by_loan_id();
    }
  },
};
