export default {
    name: "SuratBerharga",
    data() {
      return {   
        identity: {
          loanAccountId: "",
          loanData: ""
        },           
        property: {
          listElement: {
            step4: {
              securities: {
                currentPage: 1,
                rows: 0,
                perPage: 20,
                downloading: false,
                message: "",
              },
            },
          },
          animation: {
            step4: {
              securities: {
                add: {
                  isLoading: false,
                },
                edit: {
                  isLoading: false,
                },
              },
            },
          },
        },
        table: {
          header: {
            step4: {
              securities: [],
            },
          },
          data: {
            securities: [],
          },
        },
      };
    },
    methods: {      
      getSessionByAngunanData(){
        setTimeout(() => {
          if (sessionStorage.getItem("SESSION_AGUNAN")) {
            const getAgunanData = sessionStorage.getItem("SESSION_AGUNAN");
            const objData = JSON.parse(getAgunanData);
            if (objData.tloanCollateralSecurities != null) {
              this.table.data.securities = [];
              this.table.data.securities = objData.tloanCollateralSecurities;
            } else {
              this.table.data.securities = [];
            }
          } else {
            this.table.data.securities = [];
          }
        }, 2000);
      }
    },
    mounted(){      
      if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
        this.getSessionByAngunanData()      
      }
    }
  };
  