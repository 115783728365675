import FixedAssets from "./fixed-assets";
import Kendaraan from "./kendaraan";
import LogamMulia from "./logam-mulai"
import SuratKeputusan from "./sk"
import SuratBerharga from "./surat-berharga"

export default {
  name: "Agunan",
  components: {
    FixedAssets,
    Kendaraan,
    LogamMulia,
    SuratKeputusan,
    SuratBerharga
  },
  data() {
    return {
      identity: {
        loanData: ""
      },
      dataForm:{
        data: "",
      }
    };
  },
  methods: {
    async get_agunan_by_loan_id() {      
      const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_ID");
      this.identity.loanAccountId = getTokenFromSession; 
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/agunan",
            payload: {
              loanAccountNumber : this.identity.loanAccountId,
              name: ""
            }
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.data = resp.data.data;
            sessionStorage.setItem("SESSION_AGUNAN", JSON.stringify(resp.data.data));
          } else if (resp.data.code === "DATA_NOT_FOUND") {
            this.dataForm.data = "";
          } else {
            this.dataForm.data = "";
          }
        } catch (error) {
          setTimeout(() => {
            // this.handleErrorLoanInstallment(error);
          }, timeout);
        } finally {
          setTimeout(() => {
            // this.property.listElement.step3.downloading = false;
          }, timeout);
        }
      }
    },
  },
  mounted(){
    if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
      this.get_agunan_by_loan_id();
    }
  },
  destroyed(){
    sessionStorage.removeItem("SESSION_AGUNAN");
  }
};
