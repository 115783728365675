import Moment from "moment";

export default {
  name: "HistoryTransaksiPembiayaan",
  data() {
    return {
      identity: {
        loanAccountNumber: "",
        loanData: "",
      },
      property: {
        listElement: {
          historyTransaksiPembiayaan: {
            currentPage: 1,
            rows: 0,
            perPage: 5,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        historyTransaksiPembiayaan: {
          nama: "",
          noKontrak: "",
        },
      },
      table: {
        data: {
          historyTransaksiPembiayaan: [],
        },
      },
    };
  },
  methods: {
    async get_loan_transaction_history_by_loan_id() {
      const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_ID");
      this.identity.loanAccountNumber = getTokenFromSession;
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.listElement.historyTransaksiPembiayaan.downloading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/loan-history",
            payload: {
              loanAccountNumber: this.identity.loanAccountNumber,
            },
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.historyTransaksiPembiayaan.nama = resp.data.data.nama;
            this.dataForm.historyTransaksiPembiayaan.noKontrak =
              resp.data.data.noKontrak;
            this.table.data.historyTransaksiPembiayaan = resp.data.data.detail;
          } else if (resp.data.code === "DATA_NOT_FOUND") {
            this.property.listElement.historyTransaksiPembiayaan.message =
              "Belum Ada Data Yang Di Proses";
          } else {
            this.property.listElement.historyTransaksiPembiayaan.message =
              resp.data.message;
          }
        } catch (error) {
          setTimeout(() => {
            this.property.listElement.historyTransaksiPembiayaan.message = error.response
              ? `Gagal Mengunduh Data : ${error.response.statusText}`
              : "Terjadi Kesalahan";
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.historyTransaksiPembiayaan.downloading = false;
          }, timeout);
        }
      }
    },
    changeDateFormat(val) {
      Moment.locale("ID");
      if (val !== "") {
        var date = Moment(val).format("LL");
        return date;
      }
    },
    changeFormatRibuan(val) {
      var harga = val
          .toString()
          .split("")
          .reverse()
          .join(""),
        hargaRibuan = harga.match(/\d{1,3}/g);
      hargaRibuan = hargaRibuan
        .join(".")
        .split("")
        .reverse()
        .join("");
      return hargaRibuan;
    },
  },
  mounted() {
    if (sessionStorage.getItem("LOAN_ACCOUNT_ID")) {
      this.get_loan_transaction_history_by_loan_id();
    }
  },
};
